// Style przeznaczone dla nadmorzem

:root {
    --color-theme-black: #000000;
    --color-theme-white: #ffffff;
    --color-theme-dark-white: #f0f0f0;

    --color-theme-dark-grey: #797979;
    --color-theme-light-grey: #dfe0e2;
    --color-theme-grey: #ccc;
    --color-theme-green: #4dca81;

    --color-theme-primary: #2086c9;
    --color-theme-secondary: #fdbd22;
    --color-theme-background-primary: #eff4fb;
    --color-theme-background-secondary: #e3ebf6;

    --color-theme-font-primary: #101010;
    --color-theme-font-grey: #666666;
    --color-theme-font-secondary: #000000;
    --color-theme-font-header: #0c3859;

    --color-theme-wolne-od-dzis: #4dca81;
    --color-theme-wolne-od-weekendu: #da680c;

    --color-theme-alert-danger: #e91a21;

    --color-theme-filter-primary: invert(47%) sepia(10%) saturate(4761%) hue-rotate(166deg) brightness(91%)
        contrast(88%);
    --color-theme-filter-secondary: invert(82%) sepia(50%) saturate(1469%) hue-rotate(338deg) brightness(101%)
        contrast(98%);

    --color-theme-box-shadow: 0 -5px 16px 0 rgb(0 0 0 / 10%);

    --cc-btn-primary-bg: #007aff !important;
    --cc-btn-primary-hover-bg: #1570d4 !important;
    --cc-btn-secondary-hover-bg: #bdbdbd !important;
    --cc-btn-border-radius: 4px;
    --cc-toggle-bg-on: #4e4e4e !important;
    --cc-toggle-bg-off: #dcdcdc !important;
    --cc-toggle-bg-readonly: #4e4e4e !important;
    --cc-cookie-category-block-bg: #f4f4f4 !important;

    --bs-primary-rgb: #2086c9;

}

h1 {
    color: var(--color-theme-primary);
}

.header__burger {
    display: none;
}

.text-shadow{
    text-shadow: 2px 2px 3px rgba(0,0,0,.3);
}

.w-fit{
    width: fit-content;
}

.background__frontpage {
    background-image: url(https://zdjecianoclegi.pl/gfx/hero_phone.jpg);
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}
.background__frontpage.morskie {
    background-image: url(https://zdjecianoclegi.pl/gfx/hero_phone_morski.jpg);
}
.background__frontpage.gorski {
    background-image: url(https://zdjecianoclegi.pl/gfx/hero_phone_gorski.jpg);
}

@media screen and (min-width: 480px) {
    .background__frontpage {
        background-image: url(https://zdjecianoclegi.pl/gfx/hero_tablet.jpg);
    }
    .background__frontpage.morskie {
        background-image: url(https://zdjecianoclegi.pl/gfx/hero_tablet_morski.jpg);
    }
    .background__frontpage.gorski {
        background-image: url(https://zdjecianoclegi.pl/gfx/hero_tablet_gorski.jpg);
    }
    .background__frontpage.noclegi {
        background-image: url(https://zdjecianoclegi.pl/media/background/noclegi.com.jpg);
    }
}
@media screen and (min-width: 992px) {
    .background__frontpage {
        background-size: cover;
    }
    .background__frontpage.morskie {
        background-image: url(https://zdjecianoclegi.pl/gfx/hero_desktop_morski.jpg);
    }
    .background__frontpage.gorski {
        background-image: url(https://zdjecianoclegi.pl/gfx/hero_desktop_gorski.jpg);
    }
    .background__frontpage.noclegi {
        background-image: url(https://zdjecianoclegi.pl/media/background/noclegi.com.jpg);
    }
}

@media screen and (max-width: 768px){
    .py-5{
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .mt-5{
        margin-top: 1.5rem !important;
    }
}

.header__objectsMobile.local.opened {
    background-color: var(--color-theme-primary);
    box-shadow: unset;
    a{
        filter: brightness(0) invert(1);
    }
}